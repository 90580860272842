import { gql } from 'apollo-boost';
import { LOYALTY_PROMO_FRAGMENT } from '../fragments/loyaltyPromo';

export const LOYALTY_PROMO_QUERY = gql`
  query loyaltyPromo($id: Int!) {
    loyaltyPromo(id: $id) {
      ...loyaltyPromo
    }
  }
  ${LOYALTY_PROMO_FRAGMENT}
`;
