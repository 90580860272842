import { gql } from 'apollo-boost';

export const LOYALTY_PROMO_FRAGMENT = gql`
  fragment loyaltyPromo on LoyaltyPromo {
    id
    clientId
    #eventId
    name
    description
    imageUri
    state
    amount
    currencyId
    code
    codeType
    codesCount
    startDate
    finishDate
    promoCodeStartDate
    promoCodeFinishDate
    discountMaxAmount
    discountPercent
    createdAt
    updatedAt
  }
`;
